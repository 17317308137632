import React from 'react';

import { CandidateGuideFormProps } from '../';
import { Error, FormOrigin, Submitted, useForm } from 'modules/form';
import Form, { Model } from './Form';

import '../styles.scss';

const DesignerCandidateGuideForm: React.FunctionComponent<
  CandidateGuideFormProps
> = ({ description, submittedDescription, submittedTitle, title }) => {
  const {
    errorRef,
    handleSubmit,
    isError,
    isSubmitted,
    isSubmitting,
    recaptcha,
    setIsSubmitting,
    submittedRef,
  } = useForm<Model>({ formOrigin: FormOrigin.CANDIDATE_DESIGNER });

  return isError ? (
    <Error
      className="candidate-guide-settled"
      forwardedRef={errorRef}
      message={isError}
    />
  ) : isSubmitted ? (
    <Submitted
      className="candidate-guide-settled"
      description={submittedDescription?.raw}
      forwardedRef={submittedRef}
      title={submittedTitle}
    />
  ) : (
    <Form
      description={description?.description}
      handleSubmit={handleSubmit}
      isSubmitting={isSubmitting}
      recaptcha={recaptcha}
      setIsSubmitting={setIsSubmitting}
      title={title}
    />
  );
};

export default React.memo(DesignerCandidateGuideForm);
