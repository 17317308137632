import React from 'react';
import clsx from 'clsx';

import { safeTitle } from 'components/CandidateGuide/utils';
import Button from 'components/Button';

type SidebarItemProps = {
  activeSection?: string;
  children?: React.ReactNode;
  disabled?: boolean;
  onClick?: () => void;
  identifier: string;
  title?: string | null;
};

const SidebarItem: React.FunctionComponent<SidebarItemProps> = ({
  activeSection,
  children,
  identifier,
  title,
  ...props
}) => {
  const classNames = clsx(
    'cg-item',
    safeTitle(title) === activeSection && 'active',
  );

  return (
    <li className={classNames}>
      <Button ariaLabel={title || undefined} {...props}>
        <span>{identifier}</span>
        {title}
      </Button>
      {children}
    </li>
  );
};

export default React.memo(SidebarItem);
