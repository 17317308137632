import React from 'react';

import { CandidateGuideSectionProps } from '../';
import List from './List';

type SideBarProps = {
  activeSection?: string;
  handleClick: (title?: string | null) => void;
  sections: readonly (CandidateGuideSectionProps | null)[];
};

const Sidebar: React.FunctionComponent<SideBarProps> = ({
  activeSection,
  handleClick,
  sections,
}) => {
  return (
    <nav className="cg-sidebar">
      <h3>
        <span>Candidate Guide</span>
      </h3>
      <List
        activeSection={activeSection}
        handleClick={handleClick}
        sections={sections}
      />
    </nav>
  );
};

export default React.memo(Sidebar);
