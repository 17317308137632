import yup from 'modules/validation';

export type Model = {
  formAnimationLinks: string;
  formCandidateName: string;
  formExampleLinks: string;
  formPortfolioLink: string;
  formRecruiterName: string;
  formWebsiteLinks: string;
};

export const defaultValues: Model = {
  formAnimationLinks: '',
  formCandidateName: '',
  formExampleLinks: '',
  formPortfolioLink: '',
  formRecruiterName: '',
  formWebsiteLinks: '',
};

export const schema = yup.object().shape({
  formAnimationLinks: yup.string().required(),
  formCandidateName: yup.string().required(),
  formExampleLinks: yup.string().required(),
  formPortfolioLink: yup.string().required(),
  formRecruiterName: yup.string().required(),
  formWebsiteLinks: yup.string().required(),
});
