import React from 'react';
import { FieldValues } from 'react-hook-form';

import { QuestionTextAreaProps } from '../types';
import QuestionTemplate from '..';
import TextArea from 'components/Inputs/TextArea';

function QuestionTextArea<T extends FieldValues>({
  disabled,
  control,
  helpText,
  id,
  label,
  name,
  readOnly,
  ...props
}: QuestionTextAreaProps<T>) {
  return (
    <QuestionTemplate<T>
      control={control}
      disabled={disabled}
      helpText={helpText}
      name={name}>
      {({ invalid, onBlur, onChange, onFocus, ref, value }) => (
        <TextArea
          disabled={disabled}
          id={id}
          inputRef={ref}
          invalid={invalid}
          label={label}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          onFocus={onFocus}
          readOnly={readOnly}
          value={value as string}
          {...props}
        />
      )}
    </QuestionTemplate>
  );
}

export default React.memo(QuestionTextArea) as typeof QuestionTextArea;
