import React from 'react';

import { alphabetise } from 'components/CandidateGuide/utils';
import { CandidateGuideSubSectionProps } from 'components/CandidateGuide/types';
import Section from '../Section';

type SubSectionsProps = {
  activeSection?: string;
  index: number;
  subSections?: readonly (CandidateGuideSubSectionProps | null)[] | null;
};

const SubSections: React.FunctionComponent<SubSectionsProps> = ({
  index,
  subSections,
  ...props
}) => {
  return (
    <React.Fragment>
      {subSections &&
        subSections.map((subSection, subIndex) => (
          <Section
            identifier={`${index + 1}${alphabetise(subIndex)}. `}
            key={`subsection-${subSection?.title}-${subIndex}`}
            title={subSection?.title}
            {...props}
          />
        ))}
    </React.Fragment>
  );
};

export default React.memo(SubSections);
