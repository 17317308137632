import React from 'react';

import { CandidateGuideSectionProps } from '../';
import CandidateGuideFormSection, {
  CandidateGuideFormProps,
} from 'components/FormSection/CandidateGuide';
import Section from './Section';
import SubSections from './SubSections.tsx';

type SectionsProps = {
  activeSection?: string;
  formSection?: CandidateGuideFormProps | null;
  sections: readonly (CandidateGuideSectionProps | null)[];
  slug?: string | null;
};

const Sections: React.FunctionComponent<SectionsProps> = ({
  activeSection,
  formSection,
  sections,
  slug,
}) => {
  return (
    <div>
      {sections.map((section, index) => (
        <React.Fragment key={`${section?.title}-${index}`}>
          {section?.subSections ? (
            <SubSections
              activeSection={activeSection}
              index={index}
              subSections={section?.subSections}
            />
          ) : (
            <Section
              activeSection={activeSection}
              identifier={`${index + 1}. `}
              key={`${section?.title}-${index}`}
              title={section?.title}
              {...section}
            />
          )}
        </React.Fragment>
      ))}
      <CandidateGuideFormSection slug={slug} {...formSection} />
    </div>
  );
};

export default React.memo(Sections);
