import React from 'react';

import Main from 'components/Main';
import CandidateGuide from 'components/CandidateGuide';

type TemplateProps = Queries.CandidateGuideQuery;

const Template: React.FunctionComponent<TemplateProps> = ({
  contentfulCandidateGuide: props,
}) => {
  return (
    <Main>
      <CandidateGuide {...props} />
    </Main>
  );
};

export default React.memo(Template);
