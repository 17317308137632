import yup from 'modules/validation';

export type Model = {
  formCandidateName: string;
  formDashboardLinks: string;
  formLogoLinks: string;
  formPortfolioLink: string;
  formPrintLinks: string;
  formRecruiterName: string;
  formWebsiteLinks: string;
};

export const defaultValues: Model = {
  formCandidateName: '',
  formDashboardLinks: '',
  formLogoLinks: '',
  formPortfolioLink: '',
  formPrintLinks: '',
  formRecruiterName: '',
  formWebsiteLinks: '',
};

export const schema = yup.object().shape({
  formCandidateName: yup.string().required(),
  formDashboardLinks: yup.string().required(),
  formLogoLinks: yup.string().required(),
  formPortfolioLink: yup.string().required(),
  formPrintLinks: yup.string().required(),
  formRecruiterName: yup.string().required(),
  formWebsiteLinks: yup.string().required(),
});
