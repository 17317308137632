import React from 'react';
import { graphql, PageProps } from 'gatsby';

import Layout, { ILayout } from 'modules/layout';
import Meta from 'modules/meta';
import Template from './Template';

export const query = graphql`
  query CandidateGuide($id: String) {
    contentfulSiteConfig {
      ...siteConfig
    }
    contentfulCandidateGuide(id: { eq: $id }) {
      id
      slug
      title
      ...formSectionCandidateGuide
      ...metaCandidateGuide
      ...sectionCandidateGuide
    }
  }
`;

const Page: React.FunctionComponent<PageProps<Queries.CandidateGuideQuery>> &
  ILayout = ({ data }) => {
  return <Template {...data} />;
};

Page.Layout = Layout;
export default Page;

export const Head: React.FunctionComponent<
  PageProps<Queries.CandidateGuideQuery>
> = ({ data, location }) => {
  return (
    <Meta
      defaultMeta={data.contentfulSiteConfig?.defaultMeta}
      meta={data.contentfulCandidateGuide?.meta}
      pageTitle={data.contentfulCandidateGuide?.title}
      pathname={location.pathname}
    />
  );
};
