import { useCallback, useState } from 'react';

import { CandidateGuideSectionProps } from './';
import { safeTitle } from './utils';

export const useSections = (
  sections?: readonly (CandidateGuideSectionProps | null)[] | null,
) => {
  const [activeSection, setActiveSection] = useState<string | undefined>(
    safeTitle(sections && sections[0]?.title),
  );
  const handleClick = useCallback(
    (title?: string | null) => setActiveSection(safeTitle(title)),
    [],
  );

  return {
    activeSection,
    handleClick,
  };
};
