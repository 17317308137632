import React from 'react';

import { alphabetise } from 'components/CandidateGuide/utils';
import { CandidateGuideSubSectionProps } from 'components/CandidateGuide/types';
import Item from '../Item';

type SubListProps = {
  activeSection?: string;
  handleClick: (title?: string | null) => void;
  subSections: readonly (CandidateGuideSubSectionProps | null)[];
};

const SubList: React.FunctionComponent<SubListProps> = ({
  activeSection,
  handleClick,
  subSections,
}) => {
  return (
    <ol>
      {subSections.map((subSection, index) => (
        <Item
          activeSection={activeSection}
          identifier={`${alphabetise(index)}) `}
          key={`subsection-item-${index}-${subSection?.title}`}
          onClick={() => handleClick(subSection?.title)}
          title={subSection?.title}
        />
      ))}
    </ol>
  );
};

export default React.memo(SubList);
