export const alphabetise = (index: number) => {
  return String.fromCharCode(index + 1 + 64).toLowerCase();
};

export const safeTitle = (title?: string | null) => {
  return title
    ? title
        .replace(/ /g, '-')
        .replace(/'|\/|\\|:|"|`|’/g, '')
        .toLowerCase()
    : undefined;
};
