import React from 'react';

import { CandidateGuideSectionProps } from 'components/CandidateGuide/';
import Item from '../Item';
import SubList from '../SubList';

type ListProps = {
  activeSection?: string;
  handleClick: (title?: string | null) => void;
  sections: readonly (CandidateGuideSectionProps | null)[];
};

const List: React.FunctionComponent<ListProps> = ({
  activeSection,
  handleClick,
  sections,
}) => {
  return (
    <ol>
      {sections.map((section, index) => (
        <Item
          activeSection={activeSection}
          disabled={!!section?.subSections}
          identifier={`${index + 1}. `}
          key={`item-${index}-${section?.title}`}
          onClick={() => handleClick(section?.title)}
          title={section?.title}>
          {section?.subSections && (
            <SubList
              activeSection={activeSection}
              handleClick={handleClick}
              subSections={section.subSections}
            />
          )}
        </Item>
      ))}
    </ol>
  );
};

export default React.memo(List);
