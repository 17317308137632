import clsx from 'clsx';
import React, { useMemo } from 'react';

import { safeTitle } from '../../utils';

type SectionProps = {
  activeSection?: string;
  identifier: string;
  title?: string | null;
};

const Section: React.FunctionComponent<SectionProps> = ({
  activeSection,
  identifier,
  title,
}) => {
  const isActive = useMemo(
    () => safeTitle(title) === activeSection,
    [activeSection, title],
  );

  const classNames = clsx('cg-section', isActive && 'active');

  return (
    <div className={classNames} tabIndex={isActive ? 0 : -1}>
      <h2>
        <span>{identifier}</span>
        {title}
      </h2>
    </div>
  );
};

export default React.memo(Section);
