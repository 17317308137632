import React from 'react';
import { graphql } from 'gatsby';

import { CandidateGuideFormProps } from 'components/FormSection/CandidateGuide';
import { CandidateGuideSectionProps } from './types';
import { useSections } from './hooks';
import Sections from './Sections';
import Sidebar from './Sidebar';

import './styles.scss';

export type { CandidateGuideSectionProps } from './types';

export const sectionCandidateGuideFragment = graphql`
  fragment sectionCandidateGuide on ContentfulCandidateGuide {
    sections: sectionReferences {
      subSections: subSectionReferences {
        title
      }
      title
    }
  }
`;

type CandidateGuideProps = {
  formSection?: CandidateGuideFormProps | null;
  sections?: readonly (CandidateGuideSectionProps | null)[] | null;
  slug?: string | null;
};

const CandidateGuide: React.FunctionComponent<CandidateGuideProps> = ({
  formSection,
  sections,
  slug,
}) => {
  const { activeSection, handleClick } = useSections(sections);

  return sections ? (
    <div className="candidate-guide">
      <div className="container">
        <React.Fragment>
          <Sidebar
            activeSection={activeSection}
            handleClick={handleClick}
            sections={sections}
          />
          <Sections
            activeSection={activeSection}
            formSection={formSection}
            sections={sections}
            slug={slug}
          />
        </React.Fragment>
      </div>
    </div>
  ) : null;
};

export default React.memo(CandidateGuide);
