import React from 'react';
import { UseFormReset } from 'react-hook-form';
import ReCAPTCHA from 'react-google-recaptcha';

import { defaultValues, Model, schema } from './model';
import Arrow from 'modules/theme/images/button/arrow.svg';
import Button, { ButtonColour, ButtonType } from 'components/Button';
import Form, { FormErrors, Recaptcha } from 'components/Form';
import QuestionText from 'components/Questions/QuestionText';
import QuestionTextArea from 'components/Questions/QuestionTextArea';

export type { Model } from './model';

type DesignerFormType = {
  description?: string | null;
  handleSubmit: (values: Model, reset: UseFormReset<Model>) => void;
  isSubmitting: boolean;
  recaptcha: {
    handleOnError: () => void;
    handleOnExpire: () => void;
    forwardedRef: React.MutableRefObject<ReCAPTCHA | null>;
  };
  setIsSubmitting: React.Dispatch<React.SetStateAction<boolean>>;
  title?: string | null;
};

const DesignerForm: React.FunctionComponent<DesignerFormType> = ({
  description,
  handleSubmit,
  isSubmitting,
  recaptcha,
  setIsSubmitting,
  title,
}) => {
  return (
    <section className="candidate-guide-form">
      <div className="container">
        {title && <h2>{title}</h2>}
        {description && <p>{description}</p>}
        <Form<Model>
          defaultValues={defaultValues}
          onSubmit={handleSubmit}
          recaptchaRef={recaptcha.forwardedRef}
          setIsApiSubmitting={setIsSubmitting}
          schema={schema}>
          {({ control, errors, isFormSubmitting, loadRecaptcha }) => (
            <React.Fragment>
              <QuestionText
                control={control}
                label="Candidate Name"
                name="formCandidateName"
                readOnly={isFormSubmitting || isSubmitting}
              />
              <QuestionText
                control={control}
                label="Recruiter Name"
                name="formRecruiterName"
                readOnly={isFormSubmitting || isSubmitting}
              />
              <QuestionText
                control={control}
                label="Link to Portfolio"
                name="formPortfolioLink"
                readOnly={isFormSubmitting || isSubmitting}
              />
              <QuestionTextArea
                control={control}
                helpText="Links to <strong>three</strong> creative and professional logos, demonstrating creativity and artistic ability"
                label="Links to Logos"
                name="formLogoLinks"
                readOnly={isFormSubmitting || isSubmitting}
              />
              <QuestionTextArea
                control={control}
                helpText="Links to <strong>two</strong> print pieces, demonstrating typography and creative use of brand devices"
                label="Links to Print Pieces"
                name="formPrintLinks"
                readOnly={isFormSubmitting || isSubmitting}
              />
              <QuestionTextArea
                control={control}
                helpText="Links to <strong>three</strong> bespoke, responsive websites or concepts demonstrating creative use of brand devices and understanding of modern UI elements"
                label="Links to Responsive Websites"
                name="formWebsiteLinks"
                readOnly={isFormSubmitting || isSubmitting}
              />
              <QuestionTextArea
                control={control}
                helpText="Links to <strong>two</strong> examples of complex dashboards or user journeys, such as customer portals, insurance journeys or airline bookings"
                label="Links to Dashboards / User Journeys"
                name="formDashboardLinks"
                readOnly={isFormSubmitting || isSubmitting}
              />
              <div>
                <FormErrors errors={errors} />
                <div>
                  <Button
                    colour={ButtonColour.RED}
                    disabled={isFormSubmitting || isSubmitting}
                    label="Submit"
                    loading={isFormSubmitting || isSubmitting}
                    type={ButtonType.SUBMIT}>
                    <Arrow />
                  </Button>
                  <Recaptcha
                    load={loadRecaptcha}
                    ref={recaptcha.forwardedRef}
                    {...recaptcha}
                  />
                </div>
              </div>
            </React.Fragment>
          )}
        </Form>
      </div>
    </section>
  );
};

export default React.memo(DesignerForm);
