import React from 'react';

import { CandidateGuideFormProps } from './types';
import { CandidateGuideForm } from './constants';
import DesignerForm from 'components/FormSection/CandidateGuide/Designer';
import FrontendForm from 'components/FormSection/CandidateGuide/Frontend';

export type { CandidateGuideFormProps } from './types';

type CandidateGuideFormSectionProps = CandidateGuideFormProps & {
  slug?: string | null;
};

const CandidateGuideFormSection: React.FunctionComponent<
  CandidateGuideFormSectionProps
> = props => {
  switch (props.slug) {
    case CandidateGuideForm.DESIGNER:
      return <DesignerForm {...props} />;
    case CandidateGuideForm.FRONTEND:
      return <FrontendForm {...props} />;
    default:
      return null;
  }
};

export default React.memo(CandidateGuideFormSection);
